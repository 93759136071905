import React from 'react'
import GoBack from '../../../../components/pricing/goBack'
import ContactUs from '../../../../components/contactUs'
import clsx from 'clsx'
import styles2 from '../../../../components/contactUs/styles.module.css'
import './styles.css'

const PricingExpertConsultLayout = () => {

  return (
    <>
      <div className="container">
        <center className={clsx('container', styles2.mycontainer2)}>
          <p className="section__p d-flex justify-content-center flex-column">
            <div>
              <p/>
              <h3 style={{ textAlign: 'center' }}>The Expert Advantage</h3>
              <p>
                Most of our clients are on the same path as you.
              </p>
              <p>
                You see big opportunities and you see the need
                to invest in your idea to get to this market.
                Your idea is a significant improvement over other products.
              </p>
              <p>
                We respect the work you are doing.
              </p>
              <p>
                Our expert can provide assistance on your path to creating a product you can sell.<p/>
                <ol>
                  <li>
                    Your custom software may have features that need to be priced. Our expert can
                    get you a custom <b>cost breakdown</b> and <b>time estimate</b> along with
                    <b>maintenance costs</b> for your idea.<p/>
                  </li>
                  <li>
                    To reduce your initial investment and time to the app stores, our expert can provide
                    the <b>Fastest & Minimum Investment App Development Path</b> because your market
                    and investors can fund your software.<p/>
                  </li>
                  <li>
                    If you are looking for marketing advice, our expert can help with research and
                    a <b>marketing plan</b>, tools and resources.<p/>
                  </li>
                  <li>
                    If you need help getting started, our expert can help you apply for the <b>StarApp Grant</b>&nbsp;
                    at no cost.<p/>
                  </li>
                </ol>
              </p>
            </div>
          </p>
        </center>
        <center className={clsx('container', styles2.mycontainer2)}>
          <p className="section__p d-flex justify-content-center flex-column">
            <div>
              <h4 style={{ textAlign: 'center' }}>Expert's Qualifications</h4>
              <ul>
                <li><b>22 years experience</b> building high quality software products<p/></li>
                <li><b>Masters</b> in <b>Computer Science</b> from University of Southern
                  California
                  &nbsp;<b>USC</b>&nbsp;
                  was fully funded by a merit based <b>scholarship</b> because
                  he is a <b>brilliant engineer</b>.<p/>
                </li>
                <li>
                  Learn how to make your software development plan fundable by talking to our expert.
                </li>
              </ul>
            </div>
          </p>
        </center>
      </div>
      <center>
        <div className="container">
          <ContactUs></ContactUs>
        </div>
      </center>

      <GoBack prevQuestionId={'course2'}/>
    </>
  )
}

export default PricingExpertConsultLayout
